<template>
  <b-card>

    <b-row>
      <b-col
        md="4"
        sm="12"
        order="2"
        order-md="1"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                height="80"
                :src="urlPhoto !== '' ? countchangePhoto === 0 ? 'http://192.168.150.62:5000/' + urlPhoto : urlPhoto : require('@/assets/images/emptyImage.jpg')"
              />
            </b-link>
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="FileImg"
              accept=".jpg, .png"
              :hidden="true"
              plain
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
              @click="ResetImage()"
            >
              Reset
            </b-button>
            <!--/ reset -->
            <b-card-text>Allowed JPG or PNG.</b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
        md="8"
        sm="12"
        style="display: flex; justify-content: end;"
        order="1"
        order-md="2"
      >
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="SaveUser()"
          >
            {{ dataUser ? 'Edit User' : 'Save User' }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- media -->

    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <h4>Personal Info :</h4>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="First Name"
            label-for="First-Name"
          >
            <b-form-input
              v-model="FirstName"
              placeholder="First Name"
              name="FirstName"
            />
          </b-form-group>
          <!-- v-model="optionsLocal.username" -->
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Last Name"
            label-for="Last-Name"
          >
            <b-form-input
              v-model="LastName"
              placeholder="Last Name"
              name="LastName"
            />
          </b-form-group>
          <!-- v-model="optionsLocal.username" -->
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="E-mail"
            label-for="E-mail"
          >
            <b-form-input
              v-model="Email"
              placeholder="E-mail"
              name="E-mail"
            />
          </b-form-group>
          <!-- v-model="optionsLocal.username" -->
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="User Name"
            label-for="User Name"
          >
            <b-form-input
              v-model="UserName"
              name="User Name"
              placeholder="User Name"
            />
          </b-form-group>
          <!-- v-model="optionsLocal.fullName" -->
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Phone"
            label-for="Phone"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-select
                  v-model="selectedPhonePrefix"
                  :options="optionsPrefix"
                  value-field="code"
                  text-field="code"
                />
              </b-input-group-prepend>
              <cleave
                id="phone"
                v-model="Phone"
                class="form-control"
                :raw="false"
                :options="options.phone"
                placeholder="1234 567 8900"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <h4>Caretrack Info :</h4>
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Open Appt Goals"
            label-for="Open Appt Goals"
          >
            <b-form-input
              v-model="ApptGoals"
              type="number"
              placeholder="Open Appt Goals"
              name="Open Appt Goals"
            />
          </b-form-group>
          <!-- v-model="optionsLocal.username" -->
        </b-col>
        <b-col sm="2">
          <b-form-group
            label="Visit Goals"
            label-for="Visit Goals"
          >
            <b-form-input
              v-model="VisitGoals"
              type="number"
              placeholder="Visit Goals"
              name="VisitGoals"
            />
          </b-form-group>
          <!-- v-model="optionsLocal.username" -->
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Access Level"
            label-for="Access Level"
          >
            <v-select
              v-model="AccessLevel"
              label="Name"
              :options="OptionAccessLevel"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Site"
            label-for="Site"
          >
            <v-select
              v-model="Site"
              label="Name"
              :options="OptionSites"
            />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group
            label="Team"
            label-for="Team"
          >
            <v-select
              v-model="Team"
              label="Name"
              :options="OptionsTeams"
            />
          </b-form-group>
        </b-col>
        <!-- v-model="optionsLocal.company" -->

        <b-col sm="12">
          <b-row>
            <b-col sm="12">
              <h4>Password :</h4>
            </b-col>
            <b-col sm="4">
              <b-form-group
                label="New Password"
                label-for="NewPassword"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueNew"
                    name="NewPassword"
                    :type="passwordFieldTypeNew"
                    placeholder="New Password"
                    autocomplete="false"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                label="Repeat Password"
                label-for="RepeatPassword"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueRetype"
                    name="Repeat Password"
                    :type="passwordFieldTypeRetype"
                    placeholder="Repeat Password"
                    autocomplete="false"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="12">
          <h4>Permissions :</h4>
        </b-col>
        <b-col
          v-for="(permission, index) in Permissions"
          :key="index"
          lg="3"
          md="3"
          sm="12"
        >
          <b-form-checkbox
            v-model="permission.Active"
            class="custom-control-primary"
          >
            {{ permission.Name }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import catalogServices from '@core/services/catalogs/catalogService'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import userService from '@core/services/users/userService'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

const base64Encode = data => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(data)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    Cleave,
    BFormSelect,
    BFormCheckbox,
    // AppCollapse,
    // AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      FileImg: null,
      urlPhoto: '',
      countchangePhoto: 0,
      FirstName: '',
      LastName: '',
      Email: '',
      ApptGoals: '',
      VisitGoals: '',
      UserName: '',
      Phone: '',
      AccessLevel: null,
      Site: null,
      Team: null,
      OptionsTeams: [],
      OptionSites: [],
      OptionAccessLevel: [],
      passwordValueNew: '',
      passwordValueRetype: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      dataUser: null,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      selectedPhonePrefix: null,
      optionsPrefix: [{ code: '+1' }, { code: '+52' }],
      IdUser: 0,
      Permissions: [],
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    /* eslint-disable */
    FileImg(newValue, oldValue) {
      this.countchangePhoto = this.countchangePhoto + 1
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.urlPhoto = value
            })
            .catch(() => {
              this.urlPhoto = ''
            })
        } else {
          this.urlPhoto = ''
        }
      }
    },
    /* eslint-enable */
  },
  created() {
    this.getUserTeams()
    this.getSiteCatalog()
    this.getUserProfiles()
    this.getUserDataInfo()
    this.getUserSpecialPermission()
  },
  methods: {
    getUserDataInfo() {
      const id = this.$store.getters['user-profile/getIdUserProfile']
      this.IdUser = id
      if (id === 0) {
        this.dataUser = null
      } else {
        userService.getUser({
          id,
        }).then(response => {
          console.log(response.data.data)
          this.dataUser = response.data.data
          this.FirstName = this.dataUser.FirstName
          this.LastName = this.dataUser.LastName
          this.Email = this.dataUser.Email
          this.UserName = this.dataUser.Username
          this.selectedPhonePrefix = this.dataUser.code
          this.Phone = this.dataUser.phone
          this.urlPhoto = this.dataUser.Photo
          this.AccessLevel = this.dataUser.profile
          this.Site = this.dataUser.site
          this.Team = this.dataUser.team
          this.ApptGoals = this.dataUser.openApptGoals
          this.VisitGoals = this.dataUser.visitGoals
        })
      }
    },
    SaveUser() {
      if (this.validateData()) {
        const formData = new FormData()
        const Phone = this.selectedPhonePrefix.substring(1) + this.Phone
        formData.append('name', this.FirstName)
        formData.append('lastName', this.LastName)
        formData.append('username', this.UserName)
        formData.append('email', this.Email)
        formData.append('phoneNumber', Phone.replace(/\s/g, ''))
        formData.append('idProfile', this.AccessLevel.Id)
        formData.append('idSite', this.Site.Id)
        formData.append('idTeam', this.Team.Id)
        formData.append('openApptGoals', this.ApptGoals)
        formData.append('visitGoals', this.VisitGoals)
        formData.append('password', this.passwordValueNew)
        formData.append('photo', this.FileImg)
        formData.append('id', this.IdUser)
        if (this.dataUser) {
          userService.updateUser(formData).then(() => {
            userService.editUserPermission({
              id: this.IdUser,
              permissions: this.Permissions,
            }).then(() => {
              this.$router.push({ name: 'users' })
              Swal.fire(
                'Success',
                'User Updated!',
                'success',
              )
            }).catch(error => {
              this.toastErrorHandler(error)
            })
          }).catch(error => {
            this.toastErrorHandler(error)
          })
        } else {
          userService.signUpUser(formData).then(() => {
            this.$router.push({ name: 'users' })
            Swal.fire(
              'Success',
              'User Created!',
              'success',
            )
          }).catch(error => {
            this.toastErrorHandler(error)
          })
        }
      }
    },
    getUserSpecialPermission() {
      const id = this.$store.getters['user-profile/getIdUserProfile']
      userService.getUserSpecialPermissions({
        id,
      }).then(response => {
        this.Permissions = response.data.data
      })
    },
    getUserProfiles() {
      userService.getUserProfiles({}).then(response => {
        this.OptionAccessLevel = response.data.rows
      })
    },
    getUserTeams() {
      userService.getUserTeams({}).then(response => {
        this.OptionsTeams = response.data.data
      })
    },
    ResetImage() {
      this.urlPhoto = ''
      this.FileImg = null
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    getSiteCatalog() {
      catalogServices.getCatalogSelect({
        table: 'Site',
      }).then(response => {
        this.OptionSites = response.data.rows
      })
    },
    validateData() {
      let Text = ''
      if (this.FirstName.length === 0) {
        Text += '-First Name missing<br>'
      } else {
        Text += ''
      }
      if (this.LastName.length === 0) {
        Text += '-Last Name missing<br>'
      } else {
        Text += ''
      }
      if (this.Email.length === 0) {
        Text += '-Email missing<br>'
      } else {
        Text += ''
      }
      if (this.UserName.length === 0) {
        Text += '-UserName missing<br>'
      } else {
        Text += ''
      }
      if (!this.selectedPhonePrefix) {
        Text += '-Code Phone missing<br>'
      } else {
        Text += ''
      }
      if (this.Phone.length === 0) {
        Text += '-Phone missing<br>'
      } else {
        Text += ''
      }
      if (this.ApptGoals.length === 0) {
        Text += '-Appt Goals missing<br>'
      } else {
        Text += ''
      }
      if (this.VisitGoals.length === 0) {
        Text += '-Visit Goals missing<br>'
      } else {
        Text += ''
      }
      if (!this.AccessLevel) {
        Text += '-AccessLevel missing<br>'
      } else {
        Text += ''
      }
      if (!this.Site) {
        Text += '-Site missing<br>'
      } else {
        Text += ''
      }
      if (!this.Team) {
        Text += '-Team missing<br>'
      } else {
        Text += ''
      }
      /* eslint-disable */
      if (!this.dataUser) {
        if (this.passwordValueNew.length === 0) {
          Text += '-Password missing<br>'
        } else {
          if (this.passwordValueNew === this.passwordValueRetype) {
            Text += ''
          } else {
            Text += '-Password not equal <br>'
          }
        }
      }
      /* eslint-enable */
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Profile Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
        return 0
      }
      return 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
